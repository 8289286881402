import { Preferences } from '@capacitor/preferences';
import { SplashScreen } from '@capacitor/splash-screen';
import Envir from '../components/Envir';
import { setThemeFromCookie } from '../utils/ThemeUtils.js';

export const SECOND = 1000;
export const MINUTE = 60000;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = WEEK * 4;
export const YEAR = MONTH * 12;
export const SHARED_POST_SLEEPING_INTERVAL = 50;

let appWasAlreadyInvoked = false;

export function getCurrentTimeInMiliSec() {
  return new Date().getTime();
}

export async function setIOSCookie(key, value, expiration) {
  // if expiration = 0, we need to imitate session cookie, so we put 24 hours
  // expiration (edt) comes from server in seconds, so we need to convert it milliseconds : expiration * SECOND
  let expiryDateMs = (expiration === 0) ? getCurrentTimeInMiliSec() + 24 * HOUR : expiration * SECOND;
  let valueObj = {
    value: encodeURIComponent(value),
    expiration: expiryDateMs
  };
  let val = JSON.stringify(valueObj);
 
  await Preferences.set({ key: key, value: val });
}

export async function getIOSCookie(key) {
  let cookieValue;
  let valueObj;
  let value = "";

  cookieValue = await Preferences.get({ key: key })
  if (cookieValue.value !== null) {
    valueObj = JSON.parse(cookieValue.value);
    if (getCurrentTimeInMiliSec() < valueObj.expiration) {
      value = valueObj.value;
    } 
    else {
      await deleteIOSCookie(key);
    }
  }
  return value;
}

export async function deleteIOSCookie(key) {
  await Preferences.remove({ key: key });
};

export function iOSstatusBarPadding() {
  if (Envir.iOS_APP) {
    return (
      <div className="iphoneIpadInset">
      </div>
    )
  }
  else {
    return <div></div>;
  }
}

export async function hideSplashScreen() {
  try {
    await SplashScreen.hide();
  }
  catch (error) {
    console.log(error);
  }
}

export async function hideSplashScreenForApp() {
  await hideSplashScreen();
}

export async function setThemeFromCookieSync() {
  (async () => await setThemeFromCookie())(); // call async function from sync
}

export function setAppWasAlreadyInvoked(option) {
  appWasAlreadyInvoked = option;
}

export function getAppWasAlreadyInvoked() {
  // deactivate this featue because the issie is gone: App.js is not invoked twice with deep link
  // below statement will ALWAYS return false and it apices compiler
  let retCond = appWasAlreadyInvoked ? false : appWasAlreadyInvoked;
  
  return retCond; // deactivate this featue because the issie is gone (appWasAlreadyInvoked);
}