import React from "react";
import Const from './Const';
import { messageModalWindow } from './Utils.js';
import { renderVideoPlayerWrapper } from '../utils/VideoPlayerUtils.js';

class ImageUpload extends React.Component {
	constructor(props) {
		super(props);
			this.state = {
				file: '',
				imagePreviewUrl: '',
				isError: false,
				messageModal : false,
				header : '',
			    text : '',
				parent : null,
				videoFile : null
			};
	}

	disableElements() { 
		let imageDiv = document.getElementById('imageDiv');

		imageDiv.style.opacity = Const.REDUCED_OPACITY;
	}

	imagePreview(imageUrl) {
		return (
			<div className="previewComponent">
				<div id="imageDiv" className="imgPreview">
					<div className="image-parent-create-post">
						<img alt="your upload" src={ imageUrl } className="img-fluid"/>
					</div>
				</div>
			</div>
		);
	}

	videoPreview(videoUrl) {
		return (
			<div className="previewComponent">
				<div id="imageDiv" className="imgPreview">
					<div className="image-parent-create-post">
					  { renderVideoPlayerWrapper(videoUrl, true, false, 50, 50) }
					</div>
				</div>
			</div>
		);
	}

	defaultPreview() {
		return (
			<div className="previewComponent">
				<div id="imageDiv" className="imgPreview">
				</div>
			</div>
		);
	}

	render() {
		let { imagePreviewUrl, videoPreviewUrl } = this.state;

		return (
			<>
				{
					imagePreviewUrl && this.imagePreview(imagePreviewUrl)
				}

				{
					videoPreviewUrl && this.videoPreview(videoPreviewUrl)
				}

				{
					!imagePreviewUrl && !videoPreviewUrl && this.defaultPreview()
				}
				
				<div>
					{
					messageModalWindow(this, this.state.header, this.state.text)
					}
				</div>
			</>
		)
	}
}

export default ImageUpload;