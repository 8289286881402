import ReactPlayer from 'react-player';

export function renderVideoPlayer(url, props = {}) {
  return (
    <div className="">
      <ReactPlayer
        url={url}
        controls
        muted={true}
        {...props}
      />
    </div>
  );
}

export function renderVideoPlayerWrapper(videoUrl, playing, loop, width, height) {
	return (
		<div>
		  { renderVideoPlayer(videoUrl, { playing: playing, 
                                      loop: loop, 
                                      width : width.toString() + '%', 
                                      height: height.toString() + '%' 
                                    }) }
		</div>
  );
}