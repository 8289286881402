import React from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col, Form, FormGroup, Input, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBBtn, MDBModal, MDBModalBody } from 'mdbreact';
import 'react-tabs/style/react-tabs.css';
import * as MdIcons from 'react-icons/md';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import Navi from '../components/nav';
import EditUserPicture from '../components/EditUserPicture';
import GetLikes from '../components/getData/GetLikes';
import GetChannels from '../components/getData/GetChannels';
import UpdateYourPost from '../components/getData/UpdateYourPost';
import DeleteYourPost from '../components/getData/DeleteYourPost';
import Const from '../components/Const';
import { invokeService,  webSiteUrlCorrector, messageModalWindow, getUserJsonFromDBwithCookie, contactUs, 
	     followingFollowersCondition, getClassNameForWrapDiv, sleepingCat, getUserPictureClassName,
		 loadPartialJsonPosts, toolTippy, isBrowsingMode } from '../components/Utils.js';
import { removeMobilPostButton, addMobilPostButton } from '../components/MobilButtonUtils.js';
import { addScrollListener, removeScrollListener, scrollToStroredPosition, LOGIN_USER, 
	     showNewlyAddedPostOnTop, setPostsLoadinOrPostsEndOption,
		 unsetPostsLoadinOrPostsEndOption, loadingImage, coveredComponentPositionProtector } from '../components/ScrollUtils.js';
import { createAppProfileLayout, appTouchStartListener,  appTouchEndListener, appImageZoomUnsubscribe,
	     addOrientationChangeListener, removeOrientationChangeListener, appTouchMoveListener } from '../components/ImageZoomUtils.js';
import Envir from '../components/Envir';
import GetImages from '../components/getData/GetImages';
import ShareDropdown from '../components/ShareDropdown'; 
import  { getPostSharingParms } from '../utils/RenderPages.js';
import  { isMobilPlatform, isDesktop } from '../utils/AppUtils.js';
import  { postsAndFollowersClassNames } from '../utils/PostUtils.js';
import  { renderVideoPlayerWrapper } from '../utils/VideoPlayerUtils.js';

export default class ProfileOfLoginUser extends React.Component {
	constructor(props) {
		super(props);
		this.CONCIERGE_GREETING = Const.WELCOME_TO_MEDEATA;
		this.EDIT_PROFILE = 'Edit Profile';
		this.CREATE_PROFILE = 'New Profile';
		this.loginUser = props.location.params ? props.location.params.loginUser : null;
		this.state = {
			modal: false,
			messageModal: false,
			bio : '',
			website : '',
			tagline : '', 
			flag : Const.INIT_VALUE,
			zero : (Envir.DB_JSON === 'ON') ? 0 : '0',
			loginUser : this.loginUser,
			loginUserCopy : props.location.params ? this.setLoginUserHeaderCopy(props.location.params.loginUser) : null,
			postView : true,
			header : '',
			text : '',
			disableButton : true,
			canProcessContactUs : false,
			serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
			serviceStatusCode : Const.INIT_VALUE,
			serviceErrorLevel : Const.NO_ERROR_LEVEL,
			serviceInvocationError : false,
		}
		this.postsContainerRef = React.createRef();
		this.imgsConianerRef = React.createRef();
		this.loadingImgRef = React.createRef();
		this.followersRef = React.createRef();
		this.className = LOGIN_USER;		
		this.overflowScroll = true;
		this.allowToggle = true;
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onTouchStartHandler = this.onTouchStartHandler.bind(this);
		this.onTouchendHandler = this.onTouchEndtHandler.bind(this);
		this.handleTaglineChange = this.handleTaglineChange.bind(this);
		this.handleBioChange = this.handleBioChange.bind(this);
		this.handleWebSiteChange = this.handleWebSiteChange.bind(this);
		this.pinchToZoom = null;
		this.componentDidMountInvoked = false;
	}

	async componentDidMount() {
		let loginUser = this.state.loginUser;
		this.componentDidMountInvoked = true;

	    if (!loginUser || loginUser.hasOwnProperty('sharedPost')) {
			// 2nd condition was added because now we fetche limited data for shared post 
			// So now when we click login user from shared post we need to go to DB
			this.connectToDB();
		}
		else {
			this.isItAfinalPage(loginUser);
			this.setState({ loginUser : loginUser });
			this.processNewUser(loginUser);
		}
		addScrollListener(this);
		addOrientationChangeListener(this);
		createAppProfileLayout();
	}

	putNewPostOnTop() {
		showNewlyAddedPostOnTop(this);
	}

	async connectToDB() {
		let loginUser = null;
		let result;

		unsetPostsLoadinOrPostsEndOption(this, Const.END_OF_POSTS);
		result = await getUserJsonFromDBwithCookie(this);
		if ((this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) || (Envir.DB_JSON === 'OFF')) {
			loginUser = result;
			this.isItAfinalPage(loginUser);
		}
		if (loginUser) {
			loginUser.showFollowig = true;
			this.setState({ loginUser : loginUser, loginUserCopy : this.setLoginUserHeaderCopy(loginUser) });
			this.processNewUser(loginUser);
		}
	}

	processNewUser(loginUser) {
        if (!loginUser.userComplete) {
            this.toggle();
        }
	}

	componentDidUpdate() {
		let loginUser = this.state.loginUser;

		if (loginUser && loginUser.hasOwnProperty('postAdded')) {
			// post added from home or anyuser
			this.putNewPostOnTop();
			delete loginUser.postAdded;
		}
		else if (this.postsContainerRef.current || this.imgsConianerRef.current) {
			scrollToStroredPosition(this);
		}
		createAppProfileLayout();
	}

	componentWillUnmount() {
		let loginUser = this.state.loginUser;

		removeScrollListener();
		removeOrientationChangeListener();
		if (this) {
			appImageZoomUnsubscribe(this);
		}
		if (loginUser.hasOwnProperty('firstTimeIn')) {
			//concierge
			loginUser.firstTimeIn = false;
		}
	}

	contactUsActivator() {
		if (this.state.loginUser && !this.state.modal && !this.state.canProcessContactUs) {
			this.setState({ canProcessContactUs : true });
		}
	}

	onTouchStartHandler(e) {
		appTouchStartListener(e, this);
	}

	onTouchMoveHandler(e) {
		appTouchMoveListener(e);
	}

	onTouchEndtHandler(e) {
		appTouchEndListener(e);
	}

	setLoginUserHeaderCopy(loginUser) {
		let loginUserCopy = {
			userTagline : loginUser.userTagline,
			userBio : loginUser.userBio,
			userWebsite : loginUser.userWebsite
		}

		return loginUserCopy;
	}

	copyLoginUserHeader() {
		let loginUser = this.state.loginUser;
		let loginUserCopy = this.state.loginUserCopy;

		loginUser.userTagline = loginUserCopy.userTagline;
		loginUser.userBio = loginUserCopy.userBio;
		loginUser.userWebsite = loginUserCopy.userWebsite;
		this.setState({ loginUser : loginUser });
	}

	async toggle() {
		let toggleModal = !this.state.modal; // true - modal window is about to pop-up; 
                                         	 // false - modal window is about close 
		if (this.allowToggle) {
			coveredComponentPositionProtector(toggleModal, this);
			if (toggleModal) {
				this.setState({ disableButton : true});
				removeMobilPostButton(this);
			}
			else {
				this.setState({ loginUserCopy : this.setLoginUserHeaderCopy(this.state.loginUser) });
				addMobilPostButton(this);
			}
			this.setState({ modal: toggleModal, canProcessContactUs : false });
		}
	}

	async handleSubmit(event) {
		let loginUser;

		event.preventDefault();
		this.copyLoginUserHeader();
		loginUser = this.state.loginUser;
		this.disableEnableEditModalWindow(true);
		await this.storeUserBioInDB();
		if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
			this.disableEnableEditModalWindow(false);
			this.toggle();
			loginUser.userComplete = true;
			this.setState({ loginUser : loginUser });
		}
		else {
			this.disableEnableEditModalWindow(false);
		}
	}

	async storeUserBioInDB() {
		let  data = {
			user_profile_type : "p",
			user_tagline : this.state.loginUser.userTagline,
			user_bio : this.state.loginUser.userBio,
			user_company : "",
			user_website : this.state.loginUser.userWebsite,
			mode : Const.STORE_USER_BIO_MODE
		};
		await invokeService(Const.OCA_SERVICES_URL, data, this);
	}

	disableEnableUpperPartOfThescreen(disable) { 
		let upperPartOfTheScreen = document.getElementById('main');
		let editProfileButton = document.getElementById('editProfile');

		if (disable) {
			editProfileButton.setAttribute('disabled', 'disabled');
			upperPartOfTheScreen.style.opacity = Const.REDUCED_OPACITY;
		}
		else {
			editProfileButton.removeAttribute("disabled");
			upperPartOfTheScreen.style.opacity = Const.FULL_OPACITY;
		}
	}

	disableEnableEditModalWindow(disable) { 
		let submitBotton = document.getElementById('savechanges');
		let tagLineInput = document.getElementById('tagline');
		let bioInput = document.getElementById('bio');
		let websiteInput = document.getElementById('website');
		let editModalWindow = document.getElementById('editWindow');

		if (disable) {
			submitBotton.setAttribute('disabled', 'disabled');
			tagLineInput.setAttribute('disabled', 'true');
			bioInput.setAttribute('disabled', 'true');
			websiteInput.setAttribute('disabled', 'true');
			editModalWindow.style.opacity = Const.REDUCED_OPACITY;
			this.allowToggle = false;
		}
		else {
			submitBotton.removeAttribute('disabled');
			tagLineInput.removeAttribute('disabled');
			bioInput.removeAttribute('disabled');
			websiteInput.removeAttribute('disabled');
			editModalWindow.style.opacity = Const.FULL_OPACITY;
			this.allowToggle = true;
		}
	}

	getClassNameContainer(loginUser, isBrowsing) {
		let className = getClassNameForWrapDiv(loginUser, null, this );
		let isMobil = isMobilPlatform();

		if (isMobil && isBrowsing) {
			className = className + " consierge";
		}
		return className;
	}

	getClassNameProfileBlock(isBrowsing) {
		let className = "profile-block";
	    let isWinOrMacDesckTop = isDesktop();

		if (isWinOrMacDesckTop && isBrowsing) {
			className = className + ""; // " consierge-desktop";
		}
		return className;
	}

	isItAfinalPage(loginUser) {
		if (loginUser) {
			if (loginUser.hasOwnProperty('userPosts') && (loginUser.userPosts.length < loginUser.userNumberOfPosts)) {
				loginUser.isFinalPage = false;
			}
			else {
				loginUser.isFinalPage = true;
			}
		}
	}

	loadMorePostsIsNeeded() {
		let loginUser = this.state.loginUser;
		let needToLoadMorePosts = loginUser && loginUser.hasOwnProperty('userPosts') && !loginUser.isFinalPage;

		return needToLoadMorePosts;
	}

	async getPartialUserJson() {
		let loginUser = this.state.loginUser;
		let userPosts = loginUser.userPosts;
		let parms = {
				user_name : loginUser.userName,
				user_id : loginUser.userId,
				max_post_id : userPosts[userPosts.length - 1].postId
			};
		let partialJson = await loadPartialJsonPosts(this, parms);

		this.processPartialUserJson(partialJson, loginUser);
	}

	processPartialUserJson(partialJson, loginUser) { 
		if ((this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL)) {
			if (partialJson.hasOwnProperty('userPosts') && partialJson.userPosts.length > 0) {
				loginUser.userPosts = loginUser.userPosts.concat(partialJson.userPosts);
				if (loginUser.userPosts.length === loginUser.userNumberOfPosts) {
					loginUser.isFinalPage = true;
					setPostsLoadinOrPostsEndOption(this, Const.END_OF_POSTS);
				}
			}
			else {
				loginUser.isFinalPage = true;
				setPostsLoadinOrPostsEndOption(this, Const.END_OF_POSTS);
			}
			unsetPostsLoadinOrPostsEndOption(this, Const.POSTS_ARE_LOADING);
			this.setState({ loginUser : loginUser });
		}
	}

	showLoadingImage(loginUser) {
		if (loginUser.hasOwnProperty('isFinalPage') && loginUser.isFinalPage) {
		  return null;
		}
		else {
		  return loadingImage(this);
		}
	}

	getRightDiv(loginUser, multipleProps, pfClassNames) {
		if (followingFollowersCondition(loginUser, null, this))  {
			return (
				<div id='followersWrapper' className={ pfClassNames.rightDivProfile }>
					{
						<div ref={this.followersRef} id='followers' >
							<GetChannels multipleProps = { multipleProps } />
						</div>
					}
				</div>
			)
		}
		else {
			return (
			  <div id='followersWrapper'/>
			)
		}
	}

	handleTaglineChange(e) {
		let disable = (e.target.value.trimStart() === this.state.loginUser.userTagline) ? true : false;

		this.setState(prevState => ({
		  loginUserCopy: {
			...prevState.loginUserCopy,
			userTagline: e.target.value.trimStart()
		  },
		  disableButton : disable
		}))
	}

	handleBioChange(e) {
		let disable = (e.target.value.trimStart() === this.state.loginUser.userBio) ? true : false;

		this.setState(prevState => ({
		  loginUserCopy: {
			...prevState.loginUserCopy,
			userBio: e.target.value.trimStart()
		  },
		  disableButton : disable
		}))
	}

	handleWebSiteChange(e) {
		let disable = (e.target.value.trimStart() === this.state.loginUser.userWebsite) ? true : false;

		this.setState(prevState => ({
		  loginUserCopy: {
			...prevState.loginUserCopy,
			userWebsite: e.target.value.trimStart()
		  },
		  disableButton : disable
		}))
	}

	browsingModeHeading() {
		let loginUser = this.state.loginUser;
		let buttonText = loginUser.firstTimeIn ? 'Start Browsing!' : 'Happy Browsing!';

		return (
			<div>
				<h4> Welcome to Medeata! </h4>
					<p className="align-left pt-2">I'm AI Concierge ready to help you create amazing posts and stunning images. 
						You are currently in browsing read-only mode, where you can explore the application. 
						After signing in, you'll unlock the full potential of AI creation.</p>
					<p className="align-left pb-3">Discover how far my imagination could go to illustrate your thoughts! </p>
					<Link  to={{ pathname: '/Home', params: { loginUser } }}>			
						<button >
							{ buttonText }
						</button>
					</Link>
			</div>
		)
	}

	render() {
		let { zero, loginUser, loginUserCopy, serviceErrorLevel, header, text, canProcessContactUs, modal, disableButton } = this.state;
		let userName, userNumberOfPosts, userNumberOfFollowers, userNumberOfFollowing, userBio, userWebsite, userTagline;
        let loginUserPosts;
		let multipleProps;
		let editPictureParams;
		let userWebSiteFull;
		let classNameContainer;
		let classNameProfileBlock;
		let pfClassNames;
		let editOrCreateText = (loginUser && !loginUser.userComplete) ? this.CREATE_PROFILE : this.EDIT_PROFILE;
		let modalFullScreenClassName = "";
		let bodyBackgroundColorClassName = "bodyBackgroundColor";
		let formGgroupLocalScopeDdesktop = "";
		let includeEditPfofileHeading = true;
		let isBrowsing;

		if (isDesktop()) {
			formGgroupLocalScopeDdesktop = "form-group-local-scope-desktop";
		}
		else {
			// full screen modal edit profile pop-up for mobil devices
			modalFullScreenClassName = "modal-fullscreen"; 
			bodyBackgroundColorClassName += " bodyModalHeight";
		}
		if (!loginUser || loginUser.hasOwnProperty('sharedPost')) {
			if (serviceErrorLevel !== Const.NO_ERROR_LEVEL) {
				return messageModalWindow(this, header, text);
			}
			else {
				return sleepingCat();
			}
		}
		if (!this.componentDidMountInvoked) {
           return null;
		}
		({
		   userName, userNumberOfPosts, userNumberOfFollowers, userNumberOfFollowing, userBio, userWebsite, userTagline
		 } = loginUser
		);
		loginUserPosts = loginUser.userPosts;
		multipleProps = { 
			loginUser : loginUser, 
			userAndHisAssosiatedUsers : loginUser
		};
		editPictureParams = { 
			loginUser : loginUser, 
			that : this
		};
		userWebSiteFull = webSiteUrlCorrector(userWebsite);
		loginUser.whereIam = Const.I_AM_IN_MY_PROFILE;
		isBrowsing = isBrowsingMode(loginUser);
		classNameContainer = this.getClassNameContainer(loginUser, isBrowsing);
		pfClassNames = postsAndFollowersClassNames(classNameContainer);
		classNameProfileBlock = this.getClassNameProfileBlock(isBrowsing);
		return (
			<>
				{
					loginUser.userComplete 
						? <Navi navParams = { editPictureParams } />
						: <Navi loginUser = { loginUser } />
				}
				
				{
					loginUser.userComplete  && !loginUser.hasOwnProperty('topPosts')  &&
					// we just did loginUser.userComplete = true from false for new user
					<Redirect to={{ pathname: "/Home", params: { loginUser } }} />
				}

				{
					this.state.postView && !isBrowsing &&
					<div id='container' className= { pfClassNames.afterAppWrapCenterDiv }>
						<Row id="main" className={ classNameProfileBlock } >
							<Col className="center-eup" lg={3} xs={12}>
								<EditUserPicture editPictureParams ={ editPictureParams }/>
							</Col>

							<Col className="profile-info" lg={7} xs={12}>
								<Row >              
									<h4 id='nameId' className="mb-0 mt-2"> {userName} </h4>           
								</Row>  
								{
									isBrowsing
									? <Row className="mt-1"> 
									    <span id='numPostsId' className="left pr-2"> {this.CONCIERGE_GREETING} </span>
								      </Row>
									: <Row className="mt-1">
										<span id='numPostsId' className="left pr-2"> <span className="bold"> {userNumberOfPosts} </span> Posts</span>
										<span id='numFollowingId' className="left pr-2"> <span className="bold"> {userNumberOfFollowers} </span> Followers</span>
										<span id='numFollowersId' className="left"> <span className="bold">{userNumberOfFollowing} </span> Following</span>
									  </Row>
								}
								<Row className="mt-3 "> <p>{userTagline}</p> </Row>
								<Row > <p id='bioId'>{userBio}</p></Row>
								{
									userWebsite &&
									<Row><a id='webSiteId' href={userWebSiteFull} target='_blank' rel="noopener noreferrer" role="none">{userWebsite}</a></Row> 
								}
							</Col>

							{
								!loginUser.testDrive &&
								<Col className="center" lg={2} xs={12}>
									<MDBBtn id="editProfile" className="btn btn-outline-primary" onClick={this.toggle} >{ editOrCreateText }</MDBBtn>         
								</Col>
							}
						</Row>

						<Row id='postsRow'> 
							{	/*My Posts*/
								(loginUserPosts.length > 0) &&
								<div ref={this.postsContainerRef} id='posts' className={ pfClassNames.postsDivProfile }>
									{ loginUserPosts.map( loginUserPost => 
										<div className="card-wrapper" key={loginUserPost.postId}>
											<Card className="postcard" data-item="true">
												<Row className="row-margin-0 p-2">
													<div className={getUserPictureClassName(loginUser)}>
														<img src= {loginUser.userPicture} className="" alt="Avatar" />
													</div>  
												
													<div className="col-9">
														<p className="ml-0 mb-0 bold post-username">{loginUser.userName}</p>
														<p className="small">{loginUserPost.time}</p>
													</div>
												</Row>
												{
													loginUserPost.hasOwnProperty('postImg') &&
													<div className="image-parent" onTouchStart={this.onTouchStartHandler} 
														onTouchEnd={this.onTouchEndtHandler} onTouchMove={this.onTouchMoveHandler}>
														<img style={{height: loginUserPost.imgHeight}} src= { loginUserPost.postImg } alt={ Const.POST_IMAGE_ALT }></img>
													</div>
												}

												{
													loginUserPost.hasOwnProperty('postVideo') &&
													<div className="image-parent" >
														{ renderVideoPlayerWrapper(loginUserPost.postVideo, true, true, 100, 100) }
													</div>
												}

												<Row>
													<p id={loginUserPost.postId} className = "poststyle"> { loginUserPost.post } </p> 
												</Row>
												
												<Row className="icon-div p-2">
													<Col xs={3} className="mobile-col pl-0">
														{
															(loginUserPost.likesCount !== zero)
															? <GetLikes getLikesParams = { {clickedPost : loginUserPost, viewOnly : true, loginUser : loginUser }}/>
															: <div>
																<div className="left">
																	<AiIcons.AiOutlineHeart className = "home-icons-medium" />
																</div>
																<div className="left">
																	<p className="icon-count medium mb-0"> {loginUserPost.likesCount} </p>
																</div>
															</div>	
														}
													</Col>
													<Col xs={3} className="mobile-col pl-0">
														{
															<Link to={{ pathname: '/Comments', params: { clikedPost : loginUserPost, returnTo : '/Profile', 
																		viewOnly : false, anyUser : null, loginUser } }}>
																<div>
																	<div className="left">
																		{ toolTippy(FaIcons.FaRegComment, "Comment", "right", [Const.DELAY, 0], "home-icons-medium", null) }
																	</div>
																	<div className="left">
																		<p className="icon-count medium mb-0"> {loginUserPost.commentsCount} </p>
																	</div>																	
																</div>		
															</Link>   
														}
													</Col>
													<Col xs={2} className="mobile-col pl-0">
														<ShareDropdown postSharingParms = { getPostSharingParms(loginUserPost, loginUser) } /> 
													</Col>
													
													<Col xs={2} className="mobile-col">
														<div className="edit-post">
															<UpdateYourPost getYourPostParams = { {post : loginUserPost,  loginUser : loginUser, isUpdate : true, that : this } }/>			
														</div>
													</Col> 

													<Col xs={2} className="mobile-col">
														<div className="delete-post">
															<DeleteYourPost deleteYourPostParams = { {post : loginUserPost,  loginUser : loginUser, that : this }}/>			
														</div>
													</Col> 	
												</Row>
											</Card>
										</div>
										
									)}

									<div className="center">
										{
											this.showLoadingImage(loginUser)
										}				
									</div>
								</div>
									
							}
							
							{  
							   /* FOLLOWING / FOLLOWERS*/ 
							   this.getRightDiv(loginUser, multipleProps, pfClassNames) 
							}
							
						</Row>
						{
							canProcessContactUs &&
							contactUs()
						}
					</div>
				}

				{
					/* MEDIA */
					!this.state.postView && !isBrowsing &&(loginUserPosts.length > 0) &&
					<div ref={this.imgsConianerRef} >
						<GetImages anyUser  = { loginUser } />
						{
							canProcessContactUs &&
							contactUs()
						}
						<div className="center">
							{
								this.showLoadingImage(loginUser)
							}				
						</div>
					</div>
				}

				{
					/* AI concierge galery */
					isBrowsing && (loginUserPosts.length > 0) &&
					<div id='container' className= { pfClassNames.afterAppWrapCenterDiv }>
						<Row id="main"  >
							<Col className="center-eup" lg={3} xs={12}>
								<EditUserPicture editPictureParams ={ editPictureParams }/>
							</Col>

							<Col className="profile-info" lg={7} xs={12}>
								{
									this.browsingModeHeading()
								}
							</Col>
						</Row>
						<Row className="">
							<div ref={this.imgsConianerRef} className="consierge-desktop-gallery">

							<div><h5> { Const.MEDEATA_GALERY }</h5> </div>

								<GetImages anyUser  = { loginUser } />
								{
									canProcessContactUs &&
									contactUs()
								}
							</div>
						</Row>
						<Row>
							<div className="center">
									{
										this.showLoadingImage(loginUser)
									}				
							</div>
						</Row>
					</div>
				}

				{
					/*Profile edit Modal*/
					modal &&
					<div className="edit-profile" >
						<MDBModal isOpen={this.state.modal} toggle={this.toggle} overflowScroll={this.overflowScroll} 
									autoFocus={false} className= { modalFullScreenClassName }>
							<MDBModalBody className={ bodyBackgroundColorClassName } id="editWindow" >
								<Row className="alignflex" >
									<Col md={6} xs={2} className="p-0">
										<div className="modal-myheader">
											<MdIcons.MdClose className="modal-close" onClick={this.toggle} />
										</div>
									</Col>
									<Col md={6} xs={10}>
										{
														
										  disableButton 
										  ? <MDBBtn id="savechanges"  disabled = { true } className="btn btn-outline-primary right" >Save changes</MDBBtn>
										  : <MDBBtn id="savechanges"  onClick={this.handleSubmit} className="btn btn-outline-primary right" >Save changes</MDBBtn>
										}
										
									</Col>
								</Row>

								<Form>
									<FormGroup className={ formGgroupLocalScopeDdesktop }>	
										{ 
											includeEditPfofileHeading && 
											<Row>
											<h5>{ editOrCreateText }</h5>
											</Row>
										}
										
										<Row>
											<div className="edit-profile">	
												<div className="login-form-row-label-tag" autoFocus={false}>
													<label>Tagline</label> 
													<Input id="tagline" className="x-input"
														autoFocus={false}
														type="text" required
														name="tagline"
														value = {loginUserCopy.userTagline}
														placeholder="Tagline (required)"
														maxLength = {60}
														onChange={ this.handleTaglineChange }
													/>
													<div className = "error-message">
														<span>{this.state.userTagline}</span>
													</div>
												</div>

												<div className="login-form-row-label-bio">
													<label>Bio</label>
													<textarea id="bio" className="x-input"
														style = {{fontSize : 'inherit'}}
														type= "text" 
														name= "bio"
														value = {loginUserCopy.userBio}
														placeholder= "Bio"
														maxLength = {120}
														onChange={ this.handleBioChange }
													/>
												</div>

												<div className="login-form-row">
													<label>Website</label> 
													<Input id="website" className="x-input"
														type="text" 
														name="website"
														value= {loginUserCopy.userWebsite}
														placeholder = "Website"
														maxLength = {100}
														onChange={ this.handleWebSiteChange }
													/>
												</div>
											</div>
										</Row>
									</FormGroup>
								</Form>      
							</MDBModalBody> 
						</MDBModal>
					</div>
				}

				{
					messageModalWindow(this, this.state.header, this.state.text)
				}
			</>
		);
	}
}