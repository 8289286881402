import React from 'react';
import { Row } from 'reactstrap';
import Navi from '../components/nav';
import Const from '../components/Const';
import { getUserJsonFromDBwithCookie, messageModalWindow, sleepingCat } from '../components/Utils.js';

export default class Contactus extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      modal: false,
      messageModal: false,
      loginUser : props.location.params ? this.props.location.params.loginUser : null,
      //
      serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
      serviceStatusCode : Const.INIT_VALUE,
      serviceErrorLevel : Const.NO_ERROR_LEVEL,
      serviceInvocationError : false,
      flag : Const.INIT_VALUE
    }
    this.allowToggle = true;
  }

  async componentDidMount() {
    let loginUser = this.state.loginUser;
    let result;

    if (!loginUser) {
			result = await  getUserJsonFromDBwithCookie(this);
			if ((this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL)) {
        this.setState({ loginUser : result });
			}
		}
  }

  getCurrentYear() {
    return new Date().getFullYear().toString();
  }

  render() {
    const { serviceErrorLevel, loginUser, header, text } = this.state;
    const crystal = this.getCurrentYear() + '@CRYSTAL';
    
    if (!loginUser) {
			if (serviceErrorLevel !== Const.NO_ERROR_LEVEL) {
				return messageModalWindow(this, header, text);
			}
			else {
				return (
                <> 
                  {sleepingCat()}
                </>
               );
			}
		}
    else {
      loginUser.whereIam = Const.I_AM_IN_ABOUT;
      return (
        <>
          <Navi loginUser = { loginUser } />
          {
            loginUser &&
            <div id="main" className="after-app-wrap-center-div-gotop">
                <Row>
                  <div className="about">
                    <h3> Contact Us</h3>                 
                      <div className="mt-3 mb-3" >
                        <p>
                           At 
                           <a 
                              className="bold" href="https://www.crystalprismsoftware.com/" 
                              target='_blank' rel="noopener noreferrer" role="none"> Crystal Prism Software </a>
                           we value your feedback.
                        </p>
                      </div>
                      <div className="mt-3 mb-3">
                        <p>
                           For any technical issues or to report an abuse please  
                           <a 
                              className="bold" 
                              href="mailto:customerSupport@crystalprismsoftware.com"> email </a>
                           us or visit our
                           <a 
                              className="bold" 
                              href="https://www.crystalprismsoftware.com/contact-us.html"> Contact Page </a>
                        </p>  
                      </div>
                      <div className="mt-5 mb-3">
                        <span className="mb-0">{ crystal }<span className="bold" >PRISM</span>SOFTWARE</span>
                      </div>
                  </div>
                </Row>
            </div>
          }
        </>
      );
    }
  }
}