import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';
import Envir from '../components/Envir';
import { SplashScreen } from '@capacitor/splash-screen';
import { setThemeFromCookie } from '../utils/ThemeUtils.js';

export default function AppUrlListener() {
    let history = useHistory();
    useEffect(()=> {
      const hideSplashScreen = async () => {
        try {
          await SplashScreen.hide();
        }
        catch (error) {
          console.log(error);
        }
      }

      const setMyTheme = async () => {
        await setThemeFromCookie();
      }
            
      setMyTheme();
      hideSplashScreen();
     
      App.addListener('appUrlOpen', data => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        let slug = data.url.split(Envir.APP_DOMAIN).pop();
        if (slug) {
          history.push(slug); // this puts it in history.location.pathname
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
    return null;
}