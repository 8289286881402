import Envir from '../components/Envir';

const ServiceConfig = {
   URL_RESOURCE : "",
   LOGIN_URL : "",
   ACCOUT_SERVICES_URL : "",
   OCA_SERVICES_URL : "",
   MKT_SERVICES_URL : "",
   //
   USER_PICTURE_URL : "",
   USER_POST_URL : "",
   USER_VIDEO_URL : "",
   //
   VPS_SERVER_URL_SAVE_POST_INFO : "https://medeata.com/savePostInfo",
   VPS_SERVER_URL_AI : "https://medeata.com/ai",
   VPS_SERVER_URL_DALEE : "https://medeata.com/dalee",
   VPS_SERVER_URL_ILLUSTRATE : "https://medeata.com/illustrate",
   //
   INVOKE_SERVICE_PARMS : {
      headers: {
         "Accept": "application/json, text/javascript, multipart/form-data",
         "Content-Type": "application/json",
         "Authorization": ""
      },
      method: 'post'
   }
};

if (Envir.ENV !== 'prod') {
   ServiceConfig.INVOKE_SERVICE_PARMS.credentials ='include'; // all environments except ios app allow 3rd party cookies
}

if (Envir.ENV === 'dev') {
   ServiceConfig.URL_RESOURCE = "https://dcmjtk7wr7.execute-api.us-east-2.amazonaws.com/";
   ServiceConfig.USER_PICTURE_URL = "https://ocai.s3.us-east-2.amazonaws.com/prf/";
   ServiceConfig.USER_POST_URL = "https://ocai.s3.us-east-2.amazonaws.com/pst/";
   ServiceConfig.USER_VIDEO_URL = "https://ocai.s3.us-east-2.amazonaws.com/vid/";
}
else if (Envir.ENV === 'qa') {
   ServiceConfig.URL_RESOURCE = "https://dcmjtk7wr7.execute-api.us-east-2.amazonaws.com/";
   ServiceConfig.USER_PICTURE_URL = "https://ocai.s3.us-east-2.amazonaws.com/prf/";
   ServiceConfig.USER_POST_URL = "https://ocai.s3.us-east-2.amazonaws.com/pst/";
   ServiceConfig.USER_VIDEO_URL = "https://ocai.s3.us-east-2.amazonaws.com/vid/";
}
else if ((Envir.ENV === 'production') || (Envir.ENV === 'prod')) {
   ServiceConfig.URL_RESOURCE = "https://5z2xsnwmf9.execute-api.us-east-2.amazonaws.com/";
   ServiceConfig.USER_PICTURE_URL = "https://ocaiprod.s3.us-east-2.amazonaws.com/prf/";
   ServiceConfig.USER_POST_URL = "https://ocaiprod.s3.us-east-2.amazonaws.com/pst/";
   ServiceConfig.USER_VIDEO_URL = "https://ocaiprod.s3.us-east-2.amazonaws.com/vid/";
}

ServiceConfig.LOGIN_URL =  ServiceConfig.URL_RESOURCE + Envir.ENV + "/login";
ServiceConfig.ACCOUT_SERVICES_URL = ServiceConfig.URL_RESOURCE + Envir.ENV + "/accountservices";
ServiceConfig.OCA_SERVICES_URL = ServiceConfig.URL_RESOURCE + Envir.ENV + "/oca";
ServiceConfig.MKT_SERVICES_URL = ServiceConfig.URL_RESOURCE + Envir.ENV + "/mkt";

export default ServiceConfig;